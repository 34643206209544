


import { Component, Vue } from 'vue-property-decorator';
import { TNewsIssue } from '@/_types/news-issue.type';
import simplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import deletePopupContent from '@/_modules/controls/components/delete-popup-content/delete-popup-content.vue';
import cabinetNewsEditForm from '@/_modules/promo-cabinet/components/cabinet-news-edit-form/cabinet-news-edit-form.vue';
import cabinetNewsItem from '@/_modules/promo-cabinet/components/cabinet-news-item/cabinet-news-item.vue';
import draggable from 'vuedraggable';
import { TDragOptions } from '@/_types/drag-options.type';
import IconNoDiscussions from '@/_modules/icons/components/icon-no-discussions.vue';
import newsApi, {TCreateEditNewsIssueQueryParams, TEditNewsIssueParams} from '@/_api/news.api';

export const MAX_AMOUNT_OF_NEWS = 100;

@Component({
  components: {
    simplePopup,
    deletePopupContent,
    cabinetNewsEditForm,
    cabinetNewsItem,
    draggable,
    IconNoDiscussions,
  }
})
export default class CabinetNews extends Vue {

  public newsList: TNewsIssue[] = [];
  public isListLoading: boolean = false;
  public isCreateEditPopupVisible: boolean = false;
  public isDeleteNewsPopupVisible: boolean = false;
  public isDeletionInProgress: boolean = false;
  public newsIssueToDeleteId: number = null;
  public editingNewsIssue: TNewsIssue = null;
  public isNewsDragInProgress: boolean = false;
  public newsDragOptions: TDragOptions = {
    animation: 200,
    group: 'newsList',
    disabled: false,
  };

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get newsListPrepared(): TNewsIssue[] {
    return this.newsList;
  }

  public set newsListPrepared(value: TNewsIssue[]) {
    this.newsList = value;
  }

  public get isMaxAmountReached(): boolean {
    if (!this.newsListPrepared) {
      return false;
    }

    return this.newsListPrepared.length >= MAX_AMOUNT_OF_NEWS;
  }

  public get isCreateNewIssueDisabled(): boolean {
    return this.isMaxAmountReached;
  }

  public mounted(): void {
    this.getEventNews();
  }

  private async getEventNews(): Promise<void> {
    this.isListLoading = true;
    this.newsList = await newsApi.getEventNews({ eventId: this.eventId, onlyPublished: false, offset: 0, limit: MAX_AMOUNT_OF_NEWS });
    this.isListLoading = false;
  }

  private openCreateEditPopup(): void {
    this.isCreateEditPopupVisible = true;
  }

  private closeCreateEditPopup(): void {
    this.isCreateEditPopupVisible = false;
    this.clearEditingNewsIssue();
  }

  private openDeleteNewsPopup(): void {
    this.isDeleteNewsPopupVisible = true;
  }

  private closeDeleteNewsPopup(): void {
    this.isDeleteNewsPopupVisible = false;
  }

  private onEditNewsClick(issue: TNewsIssue): void {
    this.editingNewsIssue = issue;
    this.openCreateEditPopup();
  }

  private onDeleteNewsClick(issue: TNewsIssue): void {
    this.newsIssueToDeleteId = issue.id;
    this.openDeleteNewsPopup();
  }

  private onCancelDeleteNews(): void {
    this.newsIssueToDeleteId = null;
    this.closeDeleteNewsPopup();
  }

  private async onConfirmDeleteNews(): Promise<void> {
    this.isDeletionInProgress = true;

    await newsApi.deleteNewsIssue({
      eventId: this.eventId,
      newsId: this.newsIssueToDeleteId
    });

    this.newsIssueToDeleteId = null;
    this.closeDeleteNewsPopup();
    this.isDeletionInProgress = false;
    this.getEventNews();
  }

  private onFormSuccess(): void {
    this.closeCreateEditPopup();
    this.clearEditingNewsIssue();
    this.getEventNews();
  }

  private clearEditingNewsIssue(): void {
    this.editingNewsIssue = null;
  }

  private onNewsDragStart(): void {
    this.isNewsDragInProgress = true;
  }

  private onNewsDragEnd(): void {
    this.isNewsDragInProgress = false;
    this.saveNewsListSorting();
  }

  private async saveNewsListSorting(): Promise<void> {
    const resultingPromises: Promise<boolean>[] = [];
    this.newsListPrepared.forEach((news: TNewsIssue, index: number) => {
      const newsClone: TNewsIssue = {...news};
      newsClone.sorting = index + 1; // N.B. our logic is that zero sorting = no known sorting, so we have to make it non-zero
      delete newsClone.id;
      const newsData: TCreateEditNewsIssueQueryParams = {
        title: newsClone.title,
        text: newsClone.text,
        sorting: newsClone.sorting,
        is_published: newsClone.is_published,
        photos: newsClone.photos,
      };
      const payload: TEditNewsIssueParams = {
        eventId: this.eventId,
        newsId: news.id,
        data: newsData,
      };
      resultingPromises.push(newsApi.editNewsIssue(payload));
    });
    await Promise.all(resultingPromises);
    this.getEventNews();
  }

  private async onVisibilityChanged(issue: TNewsIssue): Promise<void> {
    const issueClone: TNewsIssue = {...issue};
    issueClone.is_published = !issueClone.is_published;
    delete issueClone.id;
    delete issueClone.created_at;
    const issueData: TCreateEditNewsIssueQueryParams = {...issueClone};
    const payload: TEditNewsIssueParams = {
      eventId: this.eventId,
      newsId: issue.id,
      data: issueData,
    };
    await newsApi.editNewsIssue(payload);
    this.getEventNews();
  }

}
